@import '../../../styles/variables.scss';

.Header-description {
  width:50%;
  margin:auto;
  display:table;
  color:#FFFFFF;
  margin-top:30px;
  @media #{$mobile} {
    width:90%;
  }
  .Story-paragraph {
    strong {
      color:#FFFFFF;
    }
  }
}
.AllAbout {
  height: 100%; width: 100%;
  background:url(../images/allabout-bg.jpg) no-repeat;
  background-size: cover;
  &-title {
    margin: 0;
  }
  &-subtitle {
    margin: 0;
  }
  &-header {
    @media #{$mobile} {
      background:url(../images/allabout-bg.jpg) no-repeat;
      background-size: cover;
    }
  }
  &-content {
    width: 100%;
    z-index: 100;
    &-ourPrios {
      
    }
    @media #{$tablet} {
      height: 100%;
      padding-bottom: 0;
    }
  }
}
.AllAboutArticle {
  @media #{$mobile} {
    height: auto;
  }
  @media #{$tablet} {
    overflow:hidden;
  }
  .bg-greylight {
    background-color:$color-lightgrey;
    left:0;
    position:absolute;
    top:0;
    width:100%;
    z-index:20;
    @media #{$mobile} {
      display: none;
    }
    @media #{$tablet} {
      padding-bottom:5rem;
    }
  }
  background-color:$color-lightgrey;
  font-size:0;
  height:100vh;
  position:relative;
  &-figure, &-content {
    display: inline-block;
    vertical-align: top;
  }
  .mobileArticleFigureContainer {
    height:50%;
    .AllAboutArticle-figure {
      height:100%;
      background:url('../images/allabout-article-img-our-mission.jpg') no-repeat;
      background-size: cover;
      img {
        display:none;
      }
    }
    @media #{$tablet} {
      display:inline-block;
      width:50%;
    }
    @media #{$mobile} {
      box-sizing: border-box;
      padding:3rem 3rem 0 3rem;
      width: 100%;
      img {
        display:block;
        width:100%;
      }
    }
  }
  &-figure {
    height: auto;
    position:relative;
    z-index:25;
    width: 100%;
    margin: 0;    
    @media #{$tablet} {
      //width:50%;
    }
    @media #{$mobile} {
      img {
        display:none;
        width:auto;
      }
    }
  }
  &-img {
    display: block;
    width: 100%;
  }
  &-content {
    box-sizing: border-box;
    margin-bottom:0;
    padding: 3rem 3rem 0 3rem;
    width:100%;
    @media #{$tablet} {
      width:50%;
    }
  }
  &-textContainer {
    overflow: hidden;
    height: auto;
    .Story-h3, .Story-paragraph, .Story-intertitre {
      font-size: .875rem;
      margin:0 0 2rem;
    }
    .Story-img {
      float: right;
      margin: 0 0 15px 25px;
      width:33%;
    }
    .Story-img-link {
      float: right;
      display: block;
      clear: both;
      width: 33%;
      font-size:15px;
      text-align:center;
    }
    ul {
      padding-left:16px;
    }
    @media #{$tablet} {
      height: 80%;
    }
  }
  .Header-scrollLinkContainer {
    bottom:15px;
    z-index:201;
    margin-bottom:20px;
    @media screen and (min-width:768px) and (max-width:895px) {
      display:none;
    }
     @media #{$tablet} {
      &:before {
        content:'';
        display:inline-block;
        height:1px;
        width:50%;
      }
    }
  }
  .Header-scrollLink{
    display:inline-block;
    font-size: .8125rem;
    text-align: center;
    text-shadow: 0 0 6px rgba(200, 200, 200, 0.7), 0 0 6px rgba(200, 200, 200, 0.7);
    width:50%;
  }
  &.ourMission {
    .AllAboutArticle {
      @media #{$mobile} {
        height:auto;
      }
      &-figure {
        @media #{$mobile} {
          background: url('../images/allabout-article-img-our-mission.jpg') no-repeat center center;
          background-size: cover;
        }
      }
      &-content {
        @media screen and (min-width:768px) and (max-width:895px) {
          width:100%;
        }
      }
    }
  }
  &.ourAmbition {
    p {
      .responsive_img {
        margin-bottom:100px;
      }
    }
    .AllAboutArticle {
      @media #{$mobile} {
        height:auto;
      }
      &-figure {
        background: url('../images/allabout-article-img-our-ambition.jpg') 0 0 no-repeat;
        background-size: cover;
      }
      &-content {
        @media #{$tablet} {
          float:left;
        }
        @media screen and (min-width:768px) and (max-width:895px) {
          width:100%;
        }
      }
    }
    .mobileArticleFigureContainer {
      @media #{$tablet} {
        float:right;
      }
      @media #{$mobile} {
        height:50%;
      }
    }
    .Header-scrollLinkContainer {
      margin-bottom:20px;
      @media #{$tablet} {
        &:before {
          content:none;
        }
        &:after {
          content:'';
          display:inline-block;
          height:1px;
          width:50%;
        }
      }
    }
  }
  &.ourPriorities {
    @media #{$tablet} {
      clear:both;
      overflow: visible;
    }
    .mobileArticleFigureContainer {
      @media #{$mobile} {
        padding:0;
        height:50%;
      }
      @media #{$tablet} {
        width:100%;
      }
    }
    .AllAboutArticle {
      position:relative;
      text-align: center;
      height:100vh;
      @media #{$mobile} {
        height:auto;
      }
      &-figure {
        border-bottom:2px solid #2996cf;
        width:100%;
        height:100%;
        background: url('../images/allabout-article-img-our-priorities.jpg') center center no-repeat;
        background-size: cover;
        @media #{$mobile} {
          height:auto;
          background: url('../images/allabout-article-img-our-priorities.jpg') center center no-repeat;
          background-size: cover;
        }
      }
      &-content {
        background-color: $color-white;
        box-sizing: border-box;
        display:inline-block;
        padding:3rem 5rem 5rem 5rem;
        @media #{$tablet} {
          position:absolute;
          left:25.5%;
          width:51%;
          z-index:31;
        }
        @media #{$mobile} {
          padding:3rem 3rem 5rem 3rem;
        }
      }
      &-textContainer {
        .Story-intertitre {
          display:inline;
          margin:0;
        }
      }
    }
  }
}
