@import '../../../styles/variables.scss';
/*INFOGRAPHIE PART_1*/
@media only screen and (max-width: 1446px){
	li.spe{top:-5% !important;}
}
@media only screen and (max-width: 1280px){
	li.spe{top:-10% !important;}
}
@media only screen and (max-width: 1010px){
	li.spe{top:-13% !important;}
}
@media only screen and (max-width: 865px){
	li.spe{top:-18% !important;}
}
@media only screen and (min-width: 1048px){
	br.spe{display:none;}
}


.RexelAndYou {
  height: 100%; width: 100%;
  background: url(../images/rexelandyou-bg.jpg) no-repeat;
  background-size: cover;
  &-title {
    margin: 0;
  }
  &-subtitle {
    margin: 0;
  }
  &-header {
    @media #{$mobile} {
      background: url(../images/rexelandyou-bg.jpg) no-repeat;
      background-size: cover;
    }
  }
  &-content {
    box-sizing: border-box;
    height: auto; width: 100%;
    z-index: 100;
    padding-bottom: 3em;
    @media #{$tablet} {
      height: 100%;
      padding-bottom: 0;
    }
    &#transformus {
      height: 100%;
      position: relative;
      clear: both;
      @media #{$mobile} {
        min-height: 1570px;
      }
      h2 {
        background-color:$color-yellow;
        color:$color-white;
        margin:0;
        text-align:center;
        text-transform: uppercase;
        position:relative;
        line-height:130px;
        line-height:17vh;
        height:100%;
        @media #{$mobile} {
          line-height: 9rem;
          max-height: 150px;
          vertical-align: middle;
          font-size:1rem;
        }
      }
      .transformus-title {
        height:17vh;
        line-height: 17vh;
        position:relative;
        @media #{$mobile} {
          line-height: 1.33rem;
          max-height: 150px;
        }
        .front {
          transform: rotateY(0deg);
          backface-visibility: hidden;
          position:absolute;
          width:100%;
          height:100%;
        }
        .back {
          transform: rotateY(180deg);
          backface-visibility: hidden;
          position:absolute;
          display: inline-block;
          vertical-align: middle;
          height: 17vh;
          width:100%;
          background-color:$color-yellow;
          @media #{$mobile} {
            max-height: 150px;
          }
          p {
            padding:0;
            text-align: center;
            height: auto;
            position: relative;
            line-height: 1.33rem;
            font-size:0.85rem;
            display: inline-block;
            vertical-align:middle;
            font-family:ralewaybold;
            color:#ffffff;
            text-align: center;
            width:100%;
            @media #{$mobile} {
              width:90%;
              padding:0 5%;
              max-height: 150px;
            }
          }
        }
        &:hover .front{
          transform: rotateY(180deg);
          transition: 0.6s;
          transform-style: preserve-3d;
        }
        &:hover .back{
          transform: rotateY(0deg);
          transition: 0.6s;
          transform-style: preserve-3d;
        }
      }
    }
  }
}
.RexelAndYou-energyTransformUs {
  font-size:0;
  height: 83vh;
  clear: both;
  position: relative;
  @media #{$mobile} {
    padding-top:0px;
    position:relative;
  }
  article {
    display:inline-block;
    height:83vh;
    overflow:hidden;
    position:relative;
    width:20%;
    position: relative;
    &:hover .front{
      transform: rotateY(180deg);
      transition: 0.6s;
      transform-style: preserve-3d;
    }
    &:hover .back{
      transform: rotateY(0deg);
      transition: 0.6s;
      transform-style: preserve-3d;
    }
    @media #{$mobile} {
      height:300px;
      min-height:220px;
      width:100%;
      margin-top:-4px; /* fix ipad */
    }
    .Story-h3, .Story-paragraph, .Story-intertitre {
      font-size: .875rem;
      margin:0 0 1.5rem;
    }
    .Story-intertitre {
      display:inline;
    }
    .front, .back, &.how {
      box-sizing:border-box;
      height:100%;
      line-height:140px;
      width:100%;
      top: 0;
      left: 0;
      transition: 0.6s;
      transform-style: preserve-3d;
    }
    .front {
      z-index: 2;
      transform: rotateY(0deg);
      backface-visibility: hidden;
      position:absolute;
    }
    .back {
      transform: rotateY(180deg);
      backface-visibility: hidden;
      position:absolute;
      .back-inner {
        min-height:50%;
        margin-top:30px;
        &:first-child{
          margin-top:0;
        }
      }
    }
    .front, .back {
      &.ng-show {
        opacity:1;
      }
      &.ng-hide {
        opacity:0;
      }
      &.ng-show, &.ng-hide {
        @include transition(all linear 250ms);
      }
    }
    .back {
      background-color:$color-white;
      cursor:pointer;
      padding:1rem;
      z-index:52;
      line-height: 1rem;
    }
    .front {
      cursor:pointer;
      opacity:1;
      padding:2rem;
      z-index:50;
      h3 {
        margin-top: 130px;
      }
    }
    h3 {
      display:block;
      vertical-align:middle;
      color:$color-white;
      font-size:1rem;
      line-height:1.6rem;
      margin:0;
      width:100%;
    }
    h4 {
      display: block;
      vertical-align: middle;
      color: #fff;
      font-size: 0.75rem;
      line-height: 1rem;
      margin: 0;
      width: 100%;
      font-weight: lighter;
      clear:both;
    }
    hr {
      display: block;
      width: 100%;
      float: left;
      border-top: 0;
      margin:10px 0;
    }
    &.how {
      padding:1.5rem 1rem 1.5rem 2rem;
      position:relative;
      width:50%;
      height:25%;
      &.mCS_no_scrollbar {
        padding-right:2rem;
      }
      @media #{$mobile} {
        left:0;
        position:absolute;
        top:0;
        width:100%;
      }
      .Story-paragraph {
        &:last-child {
          margin-bottom:0;
        }
      }
    }
    &.learn {
      .front {
        background:$ray-color-orange url(../images/rexelandyou-learn-bg.jpg) 100% 0 no-repeat;
        background-size:198px 140px;
        background-position: top center;
      }
      .back {
        background:$ray-color-orange;
      }
    }
    &.make {
      .front {
        background:$ray-color-purple url(../images/rexelandyou-make-bg.jpg) 100% no-repeat;
        background-size:198px 140px;
        background-position: top center;
      }
      .back {
        background:$ray-color-purple;
      }
    }
    &.earn {
      .front {
        background:$ray-color-peach url(../images/rexelandyou-earn-bg.jpg) 100% no-repeat;
        background-size:198px 140px;
        background-position: top center;
      }
      .back {
        background:$ray-color-peach;
      }
    }
    &.think {
      .front {
        background:$ray-color-blue url(../images/rexelandyou-think-bg.jpg) 100% no-repeat;
        background-size:198px 140px;
        background-position: top center;
      }
      .back {
        background:$ray-color-blue;
      }
    }
    &.work {
      .front {
        background:$ray-color-green url(../images/rexelandyou-work-bg.jpg) 100% no-repeat;
        background-size:198px 140px;
        background-position: top center;
      }
      .back {
        background:$ray-color-green;
      }
    }
  }
  .Header-scrollLinkContainer {
    bottom:auto !important;
    left:auto !important;
    margin:0;
    padding:.3rem 0;
    position:static !important;
    margin-bottom:20px;
    .Header-scrollLink {
      font-size:.8125rem;
      text-shadow:0 0 6px rgba(200, 200, 200, 0.7), 0 0 6px rgba(200, 200, 200, 0.7);
    }
  }
}
.RexelAndYouArticle {
  height: auto;
  @media #{$tablet} {
    height: 100%;
  }
  .Story-paragraph {
    font-size:0.815em;
  }
  ul {
    font-size:0.815em;
  }
  &-figure {
    box-sizing: border-box;
    position: inherit;
    top: auto; left: auto;
    height: auto; width: 100%;
    margin: 0; padding: 3em 3em 0 3em;
    background: none;
    @media #{$tablet} {
      position: absolute;
      top: 0; left: 0;
      height: 100%; width: 50%;
      padding: 0;
      background: url(../images/rexelandyou-article-img.jpg) 70% 0 no-repeat;
      background-size: cover;
    }
  }
  &-img {
    display: block;
    width: 100%;
    @media #{$tablet} {
      display: none;
      width: auto;
    }
  }
  &-content {
    box-sizing: border-box;
    position: relative;
    top: auto; left: auto;
    height: auto;
    padding: 3em 3em 0 3em;
    @media #{$tablet} {
      height: 100%;
      position: absolute;
      top: 0; left: 50%;
    }
  }
  &-textContainer {
    overflow: hidden;
    height: auto;
    @media #{$tablet} {
      height: 80%;
    }
  }
  .Header-scrollLink{
    text-shadow: 0 0 6px rgba(200, 200, 200, 0.7), 0 0 6px rgba(200, 200, 200, 0.7);
  }
}
.RexelAndYouWorldMap {
  display: table;
  height: 100%; width: 100%;
  margin-top:40px;
  &-tweetListContainer {
    display: table-cell;
    height: 100%; width: 100%;
    background: transparent;
    @media #{$tablet} {
      width: 320px;
      height: auto;
      background: #3297d1;
    }
  }
  .TweetListContainer {
    box-sizing: border-box;
    position: relative;
    padding: 10px 0 25px 0;
    &-scrollDown {
      display: block;
      position: absolute;
      bottom: 0;
      height: 25px; width: 100%;
      font-family: ralewayregular;
      font-size: .75em;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      background: #004c94;
      cursor: pointer;
      &:after {
        display: inline-block;
        font-size: 1.5em;
        content: ">";
        transform: rotate(90deg);
        position: relative;
        top: 2px; right: -10px;
      }
    }
  }
  &-tweetList {
    overflow: auto;
    margin: 0; padding: 0;
    list-style: none;
    border-top: 1px solid transparentize(#fff, .8);
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
  }
  .TweetFilter {
    position: relative;
    height: 30px;
    &-name {

    }
    &-select {
      position: absolute;
      top: 0; right: 5px;
    }
    &-option.default {
      color: grey;
    }
  }
  &-tweet {
    padding: 15px;
    border-bottom: 1px solid transparentize(#fff, .8);
  }
  .Tweet {
    background: #3297d1;
    &-img {
      float: left;
      margin-right: 10px;
    }
    &-content {
      overflow: auto;
    }
    &-name {
      margin: 0;
      font-family: ralewaymedium;
      font-size: .75em;
      color: #fff;
    }
    &-tag {
      font-family: ralewaylight;
      font-size: 1em;
      color: #125c9d;
    }
    &-time {
      float: right;
      font-family: ralewaylight;
      font-size: 1em;
      color: #125c9d;
    }
    &-text {
      margin: 0.6em 0 .5em;
      font-family: ralewaylight;
      font-size: .875em;
      color: #fff;
      line-height: 1.4;
    }
  }
  &-mapContainer {
    display: none;
    vertical-align: middle;
    width: auto;
    @media #{$tablet} {
      display: table-cell;
    }
  }
  &-map {
    width: 100%; height: 100%;
  }
  &-countryList {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    margin: 0; padding: 0;
    list-style: none;
  }
  &-country {
    position: absolute;
    height: .6em; width: .6em;
    border-radius: .3em;
    background: green;
    cursor: pointer;
  }
}
.signature {
  text-align:right;
  float:right;
}