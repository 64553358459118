@import '../../../styles/variables.scss';

.Home {
  box-sizing: border-box;
  overflow: auto;
  @media #{$tablet} {
    overflow: hidden;
  }
  padding-top: 52px;
  // height: calculate by javascript;
  height: 100%;
  width: 100%;
  background: transparent;
}
.Home-article {
  box-sizing: border-box;
  height: 30%; width: 100%;
  @media #{$tablet} {
    float: left;
    height: 33.33333%; width: 33.33333%;
    &.size-large {
      width: 50%;
    }
  }
  margin: 0; padding: 0;
}
.HomeArticle {
  position: relative;
  overflow: hidden;
  &-subdash {
    width: 2em;
    margin: .5em 1em;
    border-top-width: 0px;
    border-bottom-width: 2px;
    text-align:left;
  }
  &-header {
    position: absolute;
    bottom: 0; left: 0;
    height: auto; width: 100%;
    z-index: 103;
  }
  &-title {
    margin: 0 1em 1.5em .8em;
    @media #{$mobile} {
      text-shadow:1px 1px 6px #000000;
    }
  }
  &-subtitle {
    margin: 0 1em;
    @media #{$mobile} {
      text-shadow:1px 1px 6px #000000;
    }
  }
  &-gradient {
    position: absolute;
    bottom: 0; left: 0;
    height: 50%; width: 100%;
    background: linear-gradient( to bottom, rgba(0,0,0,0), rgba(0,0,0,.8));
    z-index: 101;
  }
  &-overlayContainer {
    position: absolute;
    bottom: 0; left: 0;
    height: 60%; width: 100%;
    min-height: 120px;
    z-index: 102;
  }
  &-overlay {
    height: 100%; width: 100%;
    &.c-azure { background: transparentize($color-azure, .3); }
    &.c-pink { background: transparentize($color-pink, .3); }
    &.c-salmon { background: transparentize($color-salmon, .3); }
    &.c-purple { background: transparentize($color-purple, .3); }
    &.c-blue { background: transparentize($color-blue, .3); }
    &.c-sand { background: transparentize($color-sand, .3); }
    &.c-lightblue { background: transparentize($color-lightblue, .3); }
    &.c-green { background: transparentize($color-green, .3); }
  }
  &-link {
    position: absolute;
    top: 0; left: 0;
    display: block;
    height: 100%; width: 100%;
    z-index: 104;
  }
  &-fade {
    position: absolute;
    top: 0; left: 0;
    display: block;
    height: 100%; width: 100%;
    background: rgba(0,0,0,.6);
    z-index: 104;
  }
}
@for $i from 1 through 8 {
  .HomeArticle:nth-child(#{$i}) {
    background: url(../images/home-article-#{$i}.jpg) no-repeat;
    background-size: cover;
  }
}
.HomeArticle:nth-child(1) .HomeArticle-overlay {
  bottom: 0;
}
