.PageHeader {
  .lang-selector-wrapper {
    @media screen and (max-width:1024px){
      display:none;
    }
    width: 50px;
    overflow: hidden;
    float: right;
    margin-right: 340px;
    background:#2996cf;
    margin-top:10px;
    height: 20px;
    padding: 5px 5px 5px 0;
    .lang-selector {
      padding-left:5px;
      color: #ffffff;
      width:80px;
      background:transparent;
      border:0;
      background:url("../images/ico-select.png") no-repeat 35px 5px;
      option{
        background:#2996cf;
      }
    }
  }
  box-sizing: border-box;
  position: absolute;
  top: 0; left: 0;
  height: 52px; width: 100%;
  background: $color-white;
  border-bottom: #2996cf 4px solid;
  box-shadow: 0px 5px 15px 0px rgba( 0, 0, 0, .5 );
  z-index: 9000;
  &-logo {
    float: left;
  }
  &-title {
    float: left;
    margin: 1.3em 0 0 1.6em;
    font-family: ralewaymedium;
    font-size: .875em; // 12px/16x
    color: #004c94;
    text-transform: uppercase;
    @media #{$mobile} {
      margin-left:0em;
    }
    &Span{
      display: none;
      @media #{$tablet} {
        display: inline;
      }
    }
  }
  &-button {
    position: absolute;
    top: 0; right: 0;
    height: 100%; width: 52px;
    border: medium none;
    border-spacing: 0;
    background: #2996cf url(../images/header-button.png) 1.2em 1em no-repeat;
  }
  &-academy {
    height: 30px;
    top: 10px;
    position: absolute;
    right: 60px;
    @media #{$mobile} {
      display:none;
    }
  }
}

.Header {
  display: table;
  height: 100%; width: 100%;
  &-content {
    padding: 3em 0;
    text-align: center;
    &.azure { background: transparentize($color-azure, .2); }
    &.pink { background: transparentize($color-pink, .2); }
    &.salmon { background: transparentize($color-salmon, .2); }
    &.purple { background: transparentize($color-purple, .2); }
    &.sand { background: transparentize($color-sand, .2); }
    &.lightblue { background: transparentize($color-lightblue, .2); }
    &.blue { background: transparentize($color-blue, .2); }
  }
  &-subtitle.Subtitle {
    margin: 0;
    font-family: ralewaylight;
    font-size: 1.4em;
    text-align: center;
    @media #{$mobile} {
      margin-top: 20px;
    }
  }
  &-title.Title {
    margin: 0 0 .5em;
    font-family: ralewaybold;
    font-size: 2em;
    text-align: center;
  }
  &-ul {
    display: block;
    margin: 0; padding: 0;
    &.framed {
      margin-top: 2em;
    }
  }
  &-linkContainer {
    display: inline-block;
    margin: 0 1em;
  }
  &-link {
    cursor: pointer;
    display:inline-block;
    &Container {
      width:138px;
      &:hover {
        .ScreenLink {opacity: 1;}
      } 
    }
    span {
      color:$color-white;
      display:inline-block;
      font-family: 'ralewaybold';
      font-size:.8125em;
      margin-top:10px;
      text-transform: uppercase;
      width:100%;
    }
  }
  &-scrollLinkContainer {
    display: none;
    position: absolute;
    bottom: 0; left: 0;
    width: 100%;
    margin: .1em 0;
    font-family: ralewaybold;
    font-size: .8125em; // 13px/16px
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom:10px;
    @media #{$tablet} {
      display: block;
    }
  }
  &-scrollLink {
    cursor: pointer;
    text-shadow: 0 0 6px rgba(0,0,0,.7), -0 -0 6px rgba(0,0,0,.7);
    &:before {
      display: inline-block;
      font-size: 1.5em;
      content: ">";
      transform: rotate(90deg);
      position: relative;
      top: 0; left: -15px;
    }
    &:after {
      display: inline-block;
      font-size: 1.5em;
      content: ">";
      transform: rotate(90deg);
      position: relative;
      top: 0; right: -15px;
    }
    &.black {
      color: #000;
    }
  }
}
.ScreenLink {
  position: relative;
  display: inline-block;
  opacity: .7;
  &-img {
    display: block;
  }
  &-sign {
    position: absolute;
    top: 50%; left: 50%;
    margin: -10px 0 0 -10px; // Arrow's width : 20px
    &.article {
      margin: -30px 0 0 -30px; // Arrow's width : 20px
    }
    &.earth {
      margin: -16px 0 0 -14px;
    }
    &.micro {
      margin: -15.5px 0 0 -11.5px;
    }
    &.people {
      margin: -14.5px 0 0 -25px;
    }
  }
}
.FramedLink {
  box-sizing: border-box;
  margin: 1em 0; padding: .2em .6em;
  font-family: ralewaylight;
  font-size: 1.4em;
  color: #fff;
  line-height: 1;
  text-decoration: none;
  border: #fff 2px solid;
  &:hover {
    background: rgba(255,255,255,0.3);
    transition: 0.3s ease;
  }
  @media #{$mobile} {
    margin:0;
  }
}
