@import '../../../styles/variables.scss';


.Welcome {
  height: 100%; width: 100%;
  background: url(../images/welcome-bg.jpg) no-repeat;
  background-size: cover;
  @media #{$mobile} {
    background-position:-208px 0;
  }
  .Header-content {
    margin-top:19%;
  }
  &-title {
    margin: 0;
  }
  &-subtitle {
    margin: 0;
  }
  &-content {
    box-sizing: border-box;
    height: auto; width: 100%;
    z-index: 100;
    padding-bottom: 3em;
    @media #{$tablet} {
      height: 100%;
      padding-bottom: 0;
    }
  }
  .signature {
    float:right;
    text-align:right;
  }
}

.WelcomeArticle {
  height: auto;
  @media #{$tablet} {
    height: 100%;
  }
  &-figure {
    box-sizing: border-box;
    position: inherit;
    top: auto; left: auto;
    height: auto; width: 100%;
    margin: 0; padding: 3em 3em 0 3em;
    background: none;
    @media #{$tablet} {
      position: absolute;
      top: 0; left: 0;
      height: 100%; width: 50%;
      padding: 0;
      background: url(../images/welcome-article-img.jpg) 70% 0 no-repeat;
      background-size: cover;
    }
  }
  &-img {
    display: block;
    width: 100%;
    @media #{$tablet} {
      display: none;
      width: auto;
    }
  }
  &-content {
    box-sizing: border-box;
    position: relative;
    top: auto; left: auto;
    height: auto;
    padding: 3em 3em 0 3em;
    @media #{$tablet} {
      height: 100%;
      position: absolute;
      top: 0; left: 50%;
    }
  }
  &-textContainer {
    overflow: hidden;
    height: auto;
    @media #{$tablet} {
      height: 80%;
    }
  }
  .Header-scrollLink{
    text-shadow: 0 0 6px rgba(200, 200, 200, 0.7), 0 0 6px rgba(200, 200, 200, 0.7);
  }
}

