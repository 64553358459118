@import '../../../styles/variables.scss';


.RexelsCulture {
  height: 100%; width: 100%;
  background: url(../images/rexelsculture-bg.jpg) no-repeat;
  background-size: cover;
  &-title {
    margin: 0;
  }
  &-subtitle {
    margin: 0;
  }
  &-content {
    height: auto;
    width: 100%;
    z-index: 100;
  }
  &-listJob {
    margin: 0; padding: 0;
    list-style: none;
    position: absolute;
    top: 0; left: 0;
    z-index: 101;
  }
  &-jobContainer {
    position: relative;
  }
  &-job {
    position: absolute;
    top: 0; left: 0;
  }
}
.RexelsCulture-content {
  min-height:100vh;
  @media #{$mobile} {
    min-height:1000px; /* fix Iphone4 */
  }
}
.RexelsCulture-baseline {
  border-top:2px solid #2996cf;
  margin:0 auto;
  padding:45px 0 40px;
  text-align: center;
  width:100%;
  p {
    display:inline-block;
    text-align: center;
    width:66%;
    line-height:1.5em;
  }
}
.RexelsCulture-listJob {
  background-color: #3297d1;
   @media #{$mobile} {
    width:100%;
  }
  .RexelsCulture-mobileJob {
    .Job-container {
      background-color:$color-white;
    }
  }
}
.RexelsCultureJob {
  position: relative;
  box-sizing: border-box;
  height: 89px;
  line-height:89px;
  text-align:center;
  width: 100%;
  transition:0.3s ease;
  @media #{$tablet} {
    width: 400px;
  }
  @media #{$mobile} {
    box-sizing:border-box;
    padding:0 20px;
    text-align:left;
  }
  border-bottom: transparentize(#fff, .85) 1px solid;
  background: #3297d1;
  cursor: pointer;
  &.isSelected,
  &:hover {
    z-index: 9999;
    background: #004c94;
    border-bottom: transparentize(#fff, .55) 1px solid;
    @media #{$mobile} {
      border-bottom:none;
    }
    &:after {
      display: inline-block;
      position: absolute;
      top: 100%; left: 50%;
      margin-top: -11px;
      margin-left: -11px;
      content: url(../images/successstoriespeople-triangle.png);
      transform: rotate(90deg);
      @media #{$tablet} {
        top:3px;
        left: auto;
        right: -11px;
        margin-top: 0;
        margin-left: 0;
        transform: rotate(0deg);
      }
      @media #{$mobile} {
        top:60px;
      }
    }
    &.isSelected {
      box-shadow: 0 10px 20px rgba(0,0,0,.2);
    }
  }
  &-icon {
    display:inline-block;
    height:59px;
    margin-right:20px;
    vertical-align:middle;
    width:59px;
    @include border-radius(30px);
  }
  &-text {
    color:#fff;
    display: inline-block;
    line-height:1.5em;
    margin:0;
    font-size: .75em;
    text-align: left;
    vertical-align:middle;
    width:180px;
     @media #{$mobile} {
      width:180px;
    }
    strong {
      display:inline-block;
      font-family: 'ralewaybold';
      font-weight:none;
      width:100%;
    }
  }
}
.Job {
  margin-left: 400px;
  width:calc(100% - 400px);
  &-header {
    box-sizing: border-box;
    height: 267px;
  }
  &-shadow {
    height: 100%; width: 100%;
    background: rgba(0,0,0,.2);
  }
  &-content {
    box-sizing: border-box;
    display: table;
    margin: 0; padding: 2em 1.5em 0 20px;
    @media #{$tablet} {
      padding:5em 70px 0 60px;
    }
    @media #{$mobile} {
      padding-top:3em;
    }
  }
  &-h3 {
    color: #004c94;
    font-family: ralewayextrabold;
    font-size: .875em;
    text-transform: uppercase;
    margin:0 0 25px 0;
  }
  &-textContainer {
    display: block;
  }
  &-paragraph {
    font-family: ralewaymedium;
    font-size: .875em;
    line-height: 1.4;
    margin-bottom:25px;
  }
  ul {
    margin:0;
    padding:0 0 0 16px;
  }
}
.JobHeader {
  position: relative;
  &.background-1 { background: url(../images/jobheader-img-1.jpg) 0 20% no-repeat; background-size: cover; }
  &.background-2 { background: url(../images/jobheader-img-2.jpg) 0 58% no-repeat; background-size: cover; }
  &.background-3 { background: url(../images/jobheader-img-3.jpg) 0 40% no-repeat; background-size: cover; }
  &.background-4 { background: url(../images/jobheader-img-4.jpg) 0 18% no-repeat; background-size: cover; }
  &.background-5 { background: url(../images/jobheader-img-5.jpg) 0 63% no-repeat; background-size: cover; }
  &.background-6 { background: url(../images/jobheader-img-6.jpg) 0 32% no-repeat; background-size: cover; }
  > img {
    bottom:-45px;
    display:block;
    height:90px;
    left:50%;
    margin-left:-45px;
    position:absolute;
    width:90px;
    @include border-radius(45px);
  }
}
