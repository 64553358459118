@import '../../../styles/variables.scss';
html{height:100%;}
body{min-height:100%;}

.clearfix:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;
     }
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */


.RexelToday {
  height: 100%; width: 100%;
  background: url(../images/rexeltoday-bg.jpg) no-repeat;
  background-size: cover;
  &-title {
    margin: 0;
  }
  &-subtitle {
    margin: 0;
  }
  &-content {
    height: auto;
  }
  &-header {
    @media #{$mobile} {
      background: url(../images/rexeltoday-bg.jpg) no-repeat;
      background-size: cover;
    }
  }
  .infographie{
    border-top: solid 25px #004c94;
    background: #f7f7f7 url(../images/infographie-top.png) center top no-repeat;
    .turquoise{color: #2996cf;}
    .orange{color: #D25535;}
    .text-right{text-align: right;}
    .text-left{text-align: left;}
    @media #{$mobile} { border-width:10px; background-size:20px 10px;}  
    h2{
      width: 8em;
      min-width: 120px;
      margin: 60px auto 0;
      text-align: center;
      color: #004c94;
      border: solid 5px #004c94;
      text-transform: uppercase;
      padding: 0.3em;
      font-size: 3em;
      font-weight: 700;
      font-family: ralewaybold;
      @media #{$mobile} { font-size:1.2em; width:10em; border-width:3px; margin-top:20px;}
    }
    .part{
      width: 100%;
      padding: 60px 0 40px 0;
      &.background-green{
        background-color: #D4ECAE;
      }
      &.background-white{
        background-color: #f7f7f7;
      }

      &.background-bluelight{
        background-color:#c1dfdc;
	  }
      &.background-blue{
        background-color: #dcebf7;
      }
      .part-inner{
        width: 80%;
        @media #{$onlyTablet}  {width: 90%;}
        @media #{$onlySmallTablet}{width: 100%;}
        margin: 0 auto;
      }
      h3{
        color: #004c94;
        font-size: 2.6em;
        text-align: center;
        font-family: ralewaylight;
        width: 80%;
        margin: 0 auto 50px auto;
        span{
          font-family: ralewaybold;
          font-weight: 700;
        }
      }
      h4{
        color: #004c94;
        font-size: 1.9em;
        text-align: center;
        font-family: ralewaylight;
        width: 90%;
        margin: 5% auto 3% auto;
      }
    }

    #part1{  
      background: #f7f7f7;
      h3 {  @media #{$mobile} { font-size:1.2em; width:100%;} }
      @media #{$tablet} { 
          #infographie-arrows-content-mob {  display:none;  }
          #infographie-arrows-content     {  display:block;  }
      }
      @media #{$mobile} { padding-top:30px;
          #infographie-arrows-content-mob {  display:table; margin:5% auto; img { width:100%; } }
          #infographie-arrows-content     {  display:none;  }
          .part-inner {  width:90%  }
      }
      table{
        width: 48%;
        margin: 0 auto;
        text-align: center;
        font-size: 1em;
        text-transform: uppercase;
        font-family: ralewaybold;
        @media #{$mobile} {
          margin-left: 0;
        }
        /* Spec texte tableau */
        @media only screen and (max-width: 480px) {  font-size:0.8em;  }
        @media #{$mobile}     { width:100%;  .logo img{min-width: 40px;} td img {  min-width:20px;  }  } 
        @media #{$onlyTablet} { width: 65%;}
        img{width: 60%;}
        .logo img{width: 80%;}
      }
      #infographie-arrows-content{
        height: 40%;
        width: 70%;
        margin: 30px 0 30px 15%;
        position: relative;
        font-family:ralewaybold;
        font-size: 0.9em;
        @media #{$onlyTablet} {
          width: 100%;
          margin-left: 0;
        }
        .arrow {
          /*display: inline-block;
          overflow: hidden;
          margin-right: -5%;
          img{width: 100%;}
          .arrow-content{
            position: absolute;
            z-index: 1;
            top: 0;
            color: #FFF;
            padding: 1em;
            height: 100%;
            width: 100%;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit
            -moz-box-sizing: border-box;    /* Firefox, other Gecko
            box-sizing: border-box;         /* Opera/IE 8+ */

          /*ul{
            margin: 0;
            padding: 0;
            li{list-style-type: none;}
          }*/

          position: relative;
          display: block;
          width: 100%;
        }
        #infographie-arrow-1{
          width: 21%;
          height: 100%;
          font-size: 0.8em;
          position: relative;
          @media #{$onlyTablet} {margin-left: 10%;}
          p{
            font-size: 1.4em;
            margin-top: 63%;
            text-align: center;
            padding-left: 25%;
          }
          .arrow-content ul li{
            text-align: center;
            line-height: 1em;
            position: absolute;
            color: #97D2EF;
          }
        }
        #infographie-arrow-2{
          width: 34.5%;
          height: 100%;
          font-size: 1em;
          position: relative;
          .arrow-content{
            padding: 0.5em 2em;
            ul li{
              text-align: center;
              margin-bottom: 6%;
              width: 50%;
              float: left;
              img{
                width: 70%;
                margin: 0 auto 1% auto;
              }
            }
          }
        }
        #infographie-arrow-3{
          width: 21%;
          height: 100%;
          font-size: 1em;
          position: relative;
          .arrow-content ul li{
            text-align: center;
            line-height: 1em;
            position: absolute;
          }
        }
        #infographie-arrow-4{
          width: 21%;
          height: 100%;
          font-size: 1em;
          position: relative;
          .arrow-content ul li{
            text-align: center;
            line-height: 1em;
            position: absolute;
          }
        }
      }
    }

    #part2{
      color: #4C4D47;
      ul, li{
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
      #world{
        width: 100%;
        position: relative;
        margin: 8% 0 4% 0;
        &>img{
          width: 35%;
          margin: 0 0 0 32.5%;
          @media #{$mobile} { 
              width: 70%;
              margin: 0 0 0 17.5%;
           }
        }
      ul li{
          font-size: 1.2em;
          font-family: ralewaybold;
          position: absolute;          
          img{width: 80px;
            @media #{$mobile} { width:40px }
          }
          span{
            display: inline-block;
            @media #{$mobile} { font-size:0.6em }
          }
        }
      }
      /* Changement apportés pour mobile */
      h3 {  @media #{$mobile} { font-size:1.2em; width:100%; margin-bottom:25%} }
      .ite-1 { width:14%; top:0; left:50%; margin-left:-7%; text-align:center; 
                @media #{$mobile} { top:0; left:51%; }
      }
      .ite-2 { top:17%; left:61%; 
                @media #{$mobile} { top:11%; left:73.5%; }
                span { 
                  float:right; margin-top:5%; padding-left:10px; white-space:pre-wrap;
                  @media #{$mobile} { position:absolute; top:-73%; left:31.5%; }
                }
      }
      .ite-3 { top:47%; left:64%;
                @media #{$mobile} { top:47%; left:80%; }
                span { 
                  float:right; padding-left:10px;
                  @media #{$mobile} { position:absolute; top:-40%; left:42%; }
                }
      }
      .ite-4 { top:75%; left:57%;
                @media #{$mobile} { top:76%; left:67%; }
                span { @media #{$mobile} { position:absolute; margin:0 0 0 20%; top:19%; left:100%; }}
      }
      .ite-5 { top:75%; right:57%; 
                @media #{$mobile} { left:26%; }
                span { @media #{$mobile} { position:absolute; margin:15% 120% 0 0; left:-125%; text-align:right;}}
      }
      .ite-6 { top:47%; right:64.5%; 
                @media #{$mobile} { right:74.5%; top:45%; }
                span { 
                  float:left; margin-top:18%; padding-right:10px;
                  @media #{$mobile} { position:absolute; top:-55%; left:-90%; }
                }
      }
      .ite-7 { top:17%; right:61%; 
                @media #{$mobile} { right:66%; top:10% }
                span { 
                  float:left; margin-top:5%; padding-right:10px;
                  @media #{$mobile} { position:absolute; top:-71%; left:-120%; text-align:center}
                }
      }
      .bottom-items { 
                @media #{$mobile} { display:table; }
                li.ite-log, li.ite-com, li.ite-fin { 
                  @media #{$mobile} { display:table-cell; font-size:0.8em; }
                }
                img { 
                  @media #{$mobile} { width:50%; }
                }
      }
      .bottom{
        width: 70%;
        @media #{$mobile} { width:100%; }
        margin: 0 auto;
        p{
          text-align: center;
          font-size: 1.5em;
          @media #{$mobile} { font-size:1.2em; }
        }
        ul li{
          display: inline-block;
          text-align: center;
          font-size: 1.2em;
          font-family: ralewaybold;
          width: 18%;
          vertical-align: top;
          &:nth-child(2){padding-top: 4%;}
          & + li{margin-left: 22%;}
          img{
            width: 75%;
            margin: 0 auto 8px 0;
          }
        }
      }
    }

    #part3{
      background: #f7f7f7;
      h3 {  @media #{$mobile} { font-size:1.2em; width:100%;} }
      h4 {  margin-top: 8%;
              @media #{$mobile} { font-size:1.2em; width:100%;}
      }
      @media #{$tablet} { 
          #part3-network-mob {  display:none;  }
          #part3-network     {  display:block;  }
      }
      @media #{$mobile} { padding-top:30px;
          #part3-network-mob {  display:table; img { width:100%; } }
          #part3-network     {  display:none;  }
          .part-inner {  width:90%  }
      }
      .relative{
        position: relative;
        width: 90%;
        margin: 0 auto 6% auto;
        color: #4c4d47;
        @media #{$mobile} {width: 100%; width:120%; margin-left:-10%}
        @media #{$onlyTablet} {width: 100%;}
        #part3-bg{
          width: 35%;
          margin: 0 0 0 32.5%;
        }
        .content{
          #part3-logo{
            position: absolute;
            top: 23%;
            left: 41%;
            z-index: 1;
            width: 18%;
            @media #{$mobile} { width:33%; left:33%; }
          }
          ul{
            margin: 0;
            padding: 0;
            @media #{$mobile} { font-size:0.8em; }
            li{
              list-style: none;
              position: absolute;
              width: 100%;
              img{width: 100%;}
              &.part3-item1{ top:0; }
              &.part3-item2{ top:0; }
              &.part3-item3{ top: 33%; }
              &.part3-item4{ top: 20%; }
              &.part3-item5{ top: 35%; }
              &.part3-item6{ top: 25%; }
              &.part3-item7{ top: 35%; }
              p{
                width: 18%;
                text-align: center;
                font-size: 1.2em;
                line-height: 0.9em;
                font-family: ralewaybold;
                position: absolute;
                @media #{$mobile} { font-size:0.8em}
                &.part3-item1{
                  top:62%;
                  left:4%;
                  @media #{$onlyTablet} {top:52%;}
                }
                &.part3-item2{
                  top:62%;
                  left:73.5%;
                  @media #{$onlyTablet} {top:55%;}
                }
                &.part3-item3{
                  top:84%;
                  left:10.5%;
                  @media #{$onlyTablet} {top:75%;}
                }
                &.part3-item4{
                  top:82%;
                  left:67%;
                  @media #{$onlyTablet} {top:77%;}
                }
                &.part3-item5{
                  top:85%;
                  left:24.2%;
                  @media #{$onlyTablet} {top:79%;}
                }
                &.part3-item6{
                  top:92%;
                  left:38%;
                  @media #{$onlyTablet} {top:88%;}
                }
                &.part3-item7{
                  top:92%;
                  left: 61%;
                  @media #{$onlyTablet} {top:88%;}
                }
              }
            }
          }
        }
      }
    }
    #part4{           
      color: #4C4D47;
      overflow: hidden;
      @media #{$onlyTablet} { width: 100%; 
                .hidden-tab { display:none; }
                h3          {  width:90%;  }
      }      
      @media #{$tablet} { 
                #world-mob {  display:none;   }
                #world     {  display:block;  }
      }
      @media #{$mobile} {
                .hidden-mob { display:none; } 
                h3         {  font-size:1.2em; width:100%;  }
                h4         {  font-size:1.2em; width:100%;  }
                #world-mob {  display:table; img { width:100%; } }
                #world     {  display:none;  }          
      }
      .part-inner{
        width: 70%;
        @media #{$mobile} {width: 90%;}
        @media #{$onlyTablet} {width: 80%;}
        ul, li{
          margin: 0;
          padding: 0;
          list-style-type: none;
        }
        #planet{
          width: 45%;
          position: relative;
          margin-top: 5%;
          padding-top: 5%;
          float:left;
          img {width: 100%; 
              @media #{$mobile} {width: 150%;}
            }
          ul li{
            position: absolute;
            width: 2.5%;
            img{width: 100%;}
            &.top{width: 4.4%;}            
          }
        }
        #list_work{
          width:55%;
          float:left;
          margin-top: 5%;
          margin-bottom: 5%;
          padding-top: 1%;
          font-family:ralewaybold;
          @media #{$mobile} { font-size:0.8em; font-family:ralewaybold; }
          li{
            img{
              width:15%;
              display: inline-block;
              @media #{$mobile} { width:30%; }
            }
            span{
              display: inline-block;
              vertical-align: top;
              margin: 6% 0 0 20px;
              @media #{$mobile} { width:50%; margin: 6% 0 0 20px; color:#2d3442;}
            }
          }
        }
      }
    }

    #part5{
      ul{
        margin: 0;
        padding: 0;
      }
      h3{ @media #{$mobile} { font-size:1.2em; width:100%; margin-bottom:1.5em;} } 
      h4{ font-size: 1.5em;
          span{font-family: ralewaybold;}
            @media #{$mobile} { font-size:1.2em; width:100%;}
      }
      .part-inner { @media #{$mobile} { width:90%} }
      .market + div > h3 { @media #{$mobile} {margin-top:10%;} }   /* Correction mobile sur deuxième titre */       
      .market{
        margin: 4% 10% 5% 10%;
        .industrial { font-family: ralewaybold; color:#1f95d1; font-size:1.2em; @media #{$mobile} { font-size:0.8em;}}
        .residential { font-family: ralewaybold; color:#f57871; font-size:1.2em; @media #{$mobile} { font-size:0.8em;}}
        .commercial { font-family: ralewaybold; color:#a4db8a; font-size:1.2em; @media #{$mobile} { font-size:0.8em;}}
        @media #{$mobile} { margin:0;}
        ul li{
          display: inline-block;
          width: 16%;
          margin: 0 8%;
          text-align: center;
          font-size: 1.2em;
          font-family: ralewaybold;
          position:relative;
          @media #{$mobile} { margin:0 1%; width:30%;}
          span{
            font-family: ralewaylight;
            font-size: 2em;
            color: #8F9187;  
            @media #{$mobile} { font-size:0.8em;}          
          }
          div + p { color:#A4DB8A; padding-top:110%; 
              @media #{$mobile} { padding-top:93%;}
          }
          img{position:absolute; top: 0%; left: 0%; width: 100%;}
        }
      }
      .cicle{
        margin: 2% 0 8% 0;
        position: relative;
        width: 100%;
        @media #{$mobile} { margin-top:10%; }
        &>img{
          margin: 0 0 0 25.5%;
          width: 50%;
          @media #{$mobile} {
            margin: 0 0 0 10%;
            width: 80%;
          }
          @media #{$onlyTablet} {
            margin: 0 0 0 27.5%;
            width: 45%;
          }
        }
        .content ul{
          margin: 0;
          padding: 0;
          li{
            font-family: ralewaybold;
            font-size: 1.2em;
            position: absolute;
            list-style: none;
            text-align: center;
            width: 30%;
            color:#4d4d4d;
            @media #{$mobile} { font-size:0.8em; }
            &.part5-item1{
              top:13%;
              left:36%;
              @media #{$onlyTablet} {left:35%;}
            }
            &.part5-item2{
              top:37%;
              left:20%;
              @media #{$mobile} {left:0;}
              @media #{$onlyTablet} {left:16%;}
            }
            &.part5-item3{
              top:39.5%;
              left:50%;
              @media #{$mobile} {left:initial;right:0;}
              @media #{$onlyTablet} {left:54%;}
            }
            &.part5-item4{
              top:81%;
              left:20%;
              @media #{$mobile} {left:0;}
              @media #{$onlyTablet} {left:16%;}
            }
            &.part5-item5{
              top:75%;
              left:50%;
              @media #{$mobile} {left:initial; right:0;}
              @media #{$onlyTablet} {left:54%;}
            }
            &.part5-item6{
              top:100%;
              left:35%;
              @media #{$onlyTablet} {left:35%;}
            }
          }
        }
      }
    }

    #part6{
      h3{ @media #{$mobile} { font-size:1.2em; width:100%; margin-bottom:1.5em;} } 
      .part-inner { @media #{$mobile} { width:90%;}  }
      color: #4C4D47;
      ul, li{
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
      p{
          text-align: center;
          font-size: 1.2em;
          color:#004c94;
          @media #{$mobile} { font-size:1em;}
      }
      #continents{
        width: 100%;
        position: relative;
        margin: 7% 0 6% 0;
        @media #{$mobile} { margin-top:10%;}
        &>img{
          width: 60%;
          margin: 0 0 0 20%;
        }
        ul li{
          font-size: 1.2em;
          font-family: ralewaybold;
          position: absolute;
          .percent {font-size:1.8em;}
          .datas {font-size:0.9em; font-family:ralewaymedium;}
          span:last-child{font-family:ralewaylight;font-size:0.7em;}
          &.green-continent {color:#2ecc71;text-align:center;top:-8%; left:3%; text-align:right; @media #{$mobile} { left:-2%;}}
          &.orange-continent {color:#e67e22;text-align:center;top:80%; left:17%; @media #{$mobile} { top:62%;left:3%;}}
          &.blue-continent {color:#3498db;text-align:center;top:-13%; left:81%; text-align:left; @media #{$mobile} { left:84%;}}
          &.red-continent {color:#e74c3c;text-align:center;top:75%; left:80%; text-align:left; @media #{$mobile} { top:55%;}}
          @media #{$mobile} { font-size:0.6em;}
        }
      }
      .bottom{
        width: 70%;
        margin: 0 auto;
        @media #{$mobile} { width:90%}
        img {
          display:table;margin:auto;width:13%;
          @media #{$mobile} { width:30%; margin-top:15%;}
        }
        .curve {
          width:8%;margin:5% auto -1% auto;
          @media #{$mobile} { width:25%; margin-top:7%;}
        }
        ul {
          display:table;
          margin:3% auto;
          li{
            @media #{$mobile} { font-size:1em; }
            display: block;
            text-align: center;
            font-size: 1.2em;
            font-family: ralewaybold;
            color:#004c94;
            span.light {
              font-family: ralewaylight;
            }
          }
        }        
      }
    }

    #part7{
      h3 { @media #{$mobile} { font-size:1.2em; } }      
      color: #4C4D47;
      ul, li{
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
      p { text-align: center;
          font-size: 1.2em;
          color:#004c94;
      }      
      #team {
        &>img {width:60%;margin:0 20%;}
        .text-right {text-align:right;float:right}
        .data { font-family:ralewaybold; padding-left:1% }
      }
      #parallax { position:relative; }
      #inc_years {
       position:absolute;
       font-size:7em;
       @media only screen and (min-width: 480px) and (max-width: 768px) { font-size:4em }
       @media only screen and (min-width: 768px) and (max-width: 900px) { font-size:6em }
       @media only screen and (min-width: 901px) and (max-width: 1000px) { font-size:6.5em }
       @media only screen and (min-width: 1001px) and (max-width: 1100px) { font-size:6.75em }
       @media only screen and (min-width: 1001px) and (max-width: 1200px) { font-size:7em }
       @media only screen and (min-width: 1201px) and (max-width: 1350px) { font-size:7.5em }
       @media only screen and (min-width: 1351px) and (max-width: 1500px) { font-size:8em }
       @media only screen and (min-width: 1501px) and (max-width: 1700px) { font-size:9em }
       @media only screen and (min-width: 1701px) and (max-width: 1900px) { font-size:10em }
       @media only screen and (min-width: 1900px) { font-size:11em }     
       top:20%;
       left:30%;
      }
      .part-inner { @media #{$mobile} { width:90%; } }
      .progress-graph {
        display: none;
        strong { font-family:ralewaybold }
        padding:0 15%;
        @media #{$mobile} { padding:0 5%; font-size:0.5em; }
        img {width:100%}
        .progress-number { width:48%; display:inline-block; color:#1f95d1; padding:1.8% 0 3% 1%; font-size:1.2em; @media #{$mobile} { left:initial; right:0; }}
        .progress-number-right {width:48%; display:inline-block;float:right; color:#60a83f; padding:1.8% 1% 3% 0; font-size:1.2em;}
        .progress-img-man { 
            position:absolute; top:0; left:0; width:20%;
            @media #{$mobile} { left:initial; right:0; }
        }
      }
      .progress-number-right {float:right}
      ul.team-list-1 {
        display:table;
        margin:auto;
        width:100%;
      }
      ul.team-list-1 li {
        display:table-cell;
        width:33%;
        @media #{$onlyTablet}  {width:32%;}
        .red {color:#e74c3c}
        .black {color:#3b3b3b}
        .blue {color:#3498db}
        span {display:block;text-align:center;margin-bottom:3%;
          &:last-child {font-family:ralewaybold;
              @media #{$mobile} { font-size:0.8em; }
          }
        }
        img {display:table;width:55%;margin:15% auto 5%}
        div + span { font-size:1.5em; color:#808080; @media #{$mobile} { font-size:1em; }}
      }
      ul.team-list-2 {display:table;margin:3% auto 10% 0}
      ul.team-list-2 li {
        position: relative;
        width:32%;
        display:table-cell;
        text-align: center;
        color:#004c94;
          @media #{$mobile} { font-size:0.75em; }
        strong {font-family:ralewaybold;}
        span {text-align:center;margin-bottom:3% }
        img {display:table;width:35%;margin:15% auto 5%;

            @media #{$mobile} { width:55%; }
        }
        img + span {font-family:ralewaybold; }
        .team-padding { padding:0 3% }
      }
    }

    #part8 {
      h3 { @media #{$mobile} { font-size:1.2em; } }
      color: #4C4D47;
      background:#e5f2d6;
      padding: 0 0 40px 0 !important;
	  	& img.bg{width:100%;}  
      ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
	  	.part-inner{overflow:hidden;
           @media #{$mobile} { width:90% }
      }	
	  	& ul{
          width:80%; margin-left:13%; position:relative;
          @media #{$mobile} { width:100%; height:400px; display:table; margin:auto;}
	  		  li, span{color:#004c94;}
	  	}
      & #parallax_montain { position:absolute; top:0%; left:50%; width:80%; margin-left:-40%; }
	  	& #montain ul li {
	  		float:left; width:14%; margin: 0 2%; text-align:center;
				img{width:100%;}
				span{margin-top:10px; display:block; line-height:1.3em;}       
	  	}
      & #montain ul li.mob-text { display : none }
      @media #{$mobile} {
          & #montain ul li        {  font-size:0.9em }
          & #montain ul li img    {  width:50% } 
          & #montain ul li.item-1 {  position:absolute; width:45%; top:0; left:0; }
          & #montain ul li.item-2 {  position:absolute; width:45%; top:0; left:50%;  }
          & #montain ul li.item-3 {  position:absolute; width:45%; top:50%; left:0;  }
          & #montain ul li.item-4 {  position:absolute; width:45%; top:100%; left:0;  }
          & #montain ul li.item-5 {  position:absolute; width:45%; top:85%; left:50%;  }
          & #montain ul li.mob-text{ position:absolute; width:45%; top:55%; left:50%; display:block;
              span:first-child {  background-color: #004c94; width:2px; height:25px; position:absolute; left:50%; top:-80%; }
              span:last-child {  margin-top:1.5%; }
          }
          & #asterix { height:200px; }
          & #asterix ul li.item-1 {  position:absolute; width:40%; top:65%; left:40%;  }
          & #asterix ul li.item-2 {  position:absolute; width:85%; top:6%; left:30%; margin-left:0;  }
          & #asterix .mobile-hide {  display:none !important; }

        }      
	 		& #asterix{
	  		width:100%; position:relative; overflow:hidden;
        li.item-1 {
          margin-left:12%; position:relative; width:30%;
          span { display:block; height:50px; width:2px; background-color:#004c94; position:absolute; top:0; left:50% }
          span:last-child { padding-top:58px; background:none; width:130%; left:-15%;}
        }
        li.item-2 {
          margin-left:69%; position:relative; width: 23%;
          span { display:block; height:50px; width:2px; background-color:#004c94; position:absolute; top:0; left:50%; }
          img { padding-top:58px; }
        }        
	  	}
    }
        #disclaimer{
          text-align: center;
          font-size: 11px;
          padding-top: 80px;
          @media #{$mobile} {
            padding-top: 0;
          }
        }
		#part9{			
			height:100%;
			background-color:#d7e8eb;      
			padding-bottom: 10%;
      color:#004c94;
      ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
			@media #{$mobile}{
				#trace_date{display:none;}
				#trace_date_mobile{display:block;}
			}
			div.roundBig{
				width:25%;
				background:red;
				border-radius:100%;
				position:relative;
				img{position:absolute;top: 10%;left: 10%;width: 80%;  z-index:9999;}
			}
			div.roundSmall{
				width:20%;
				background:red;
				border-radius:100%;
				position:relative;
				img{position:absolute;top: 10%;left: 10%;width: 80%;  z-index:9999;}
			}
			.date1{top:3.6%; left:0; width: 30%;
				div{background-color:#b378ba;}
				p{margin: 5% 0 0 0;}
			}
			.date2{bottom: 91.9%; left:35%; width: 30%;
				div{background-color:#866399;}
				p{margin:0 0 5% 0;}
			}
			.date3{top:4%; left:66%; width: 30%;
				div{background-color:#565683;}
				p{margin: 5% 0 0 0;}
			}
			.date4{top: 14.6%;right: -1.5%;width: 30%;
				div{float: right; background-color:#5b7694;}
				p{padding: 0;margin: 0 5% 0 0;float: right;display: block;width: 70%; text-align: right !important; white-space: normal !important;}
			}
			.date5{bottom: 68.8%; left:35%; width: 30%;
				div{background-color:#8f609a;}
				p{margin:0 0 5% 0;}
			}
			.date6{top:27%; left:66%; width: 30%;
				div{background-color:#685581;}
				p{margin: 5% 0 0 0;}
			}
			.date7{top: 38.5%;left:-0.9%;width: 30%;
				div{float: left; background-color:#b375ab;}
				p{padding: 0; float: left;display: block;width: 75%; margin:0 0 0 5%;}
			}
			.date8{bottom:45%; left:17%; width: 30%;
				div{background-color:#755d8c;}
				p{margin:0 0 5% 0;}
			}
			.date9{top:50%; left:42%; width: 30%;
				div{background-color:#5b5f8b;}
				p{margin:5% 0 0 0;}
			}
			.date10{bottom:45.8%; left:70%; width: 30%;
				div{background-color:#526684;}
				p{margin:0 0 5% 0;}
			}
			.date11{top: 61.8%;right: -0.7%;width: 30%;
				div{float: right; background-color:#4e7786;}
				p{padding: 0;margin: 0 5% 0 0;float: right;display: block;width: 74%; text-align: right !important; white-space: normal !important;}
			}
			.date12{top:73.1%; left:70%; width: 30%;
				div{background-color:#46737f;}
				p{margin:5% 0 0 0;}
			}
			.date13{top: 72.8%;left: 42%;width: 30%;
				div{background-color:#6f8597;}
				p{text-align: right; position: absolute;bottom:100%;left: -69%; text-align: right !important; margin:0 0 5% 0;}
			}
			.date14{top:73.1%; left:18%; width: 30%;
				div{background-color:#6c768c;}
				p{margin:5% 0 0 0;}
			}
			.date15{top: 85%; left: -1.0%;width: 30%;
				div{float: left; background-color:#5b6272;}
				p{padding: 0;margin: 0 0 0 5%;float: left;display: block;width: 75%;}
			}
			.date16{top:96.3%; left:18%; width: 30%;
				div{background-color:#2b5e5d;}
				p{margin:5% 0 0 0;}
			}
			.date17{bottom:-1%; left:48%;width: 30%;
				div{background-color:#2f696a;}
				p{margin:0 0 5% 0;}
			}
			.date18{top: 95.65%; right: 3%;  width: 30%;
				div{float: right; background-color:#306a6a;}
				p{float: right; margin:5% 0 0 0; text-align: right !important; clear: both;}
			}
			#trace_date_mobile{
				position:relative;
				img{position:absolute; left:0;}
					
			}
			#trace_date{
				position:relative;
				padding-top: 7%;
				p{text-align:left;}
				@media #{$onlySmallTablet} {margin: 0 5%;}
				& .titleNv-1{
					position:absolute;
					font-weight:bold;
					width:30%;
					p{white-space: nowrap;}
					@media #{$onlyGiantDesktop} {p{font-size:1.8em; white-space: nowrap;}}
					@media #{$onlyBigDesktop} {
						p{font-size:1.5em;}
						p{left:-71%;}
					}
					@media #{$only1366Desktop} {
						p{bottom:100%;left: -51%;}
					}
					@media #{$onlySmallTablet} {
						p{font-size:1.2em;}
						p{left: -75%;}
					}
				}
				& .titleNv-2{
					position:absolute;
					width:30%;
					p{white-space: nowrap;}
					@media #{$onlyGiantDesktop} {p{font-size:1.5em; white-space: nowrap;}}
					@media #{$onlyBigDesktop} {p{font-size:1.2em;}}
					@media #{$onlySmallTablet} {p{font-size:.9em;}}
				}
				li span{font-weight:bold;}
				@media #{$only1920} {
					.date2{}
					.date5{top:23.7%;}
					.date8{top:45.8%;}
					.date10{top:41.8%;}
					.date17{top:91.8%;}
				}
				@media #{$onlySmallTablet} {
					.date2{bottom:91.5%;}
					.date3{top: 4.5%;}
					.date6{top: 27.4%;}
					.date5{bottom: 68.5%;}
					.date9{top: 50.5%;}
					.date10{left:65%;}
				}
			}
		}

    


  }

}






























