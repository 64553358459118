@import '../../../styles/variables.scss';


.Commitments {
  height: 100%; width: 100%;
  background: url(../images/commitments-bg.jpg) no-repeat;
  background-size: cover;
  @media #{$mobile} {
    background-size:425%;
  }
  .Header-content {
    &.green {
      background:rgba(0, 92, 48, 0.8);
    }
    @media #{$mobile} {
      padding:4em 0 4em;
      h1 {
        font-size:1em;
      }
      img {
        width:50px;
        padding-top:10px;
      }
    }
    @media #{$tablet} {
      padding-bottom:2em;
    }
  }
  &-header {
    @media #{$mobile} {
      background: url(../images/commitments-bg.jpg) no-repeat;
      background-size: cover;
    }
  }
  #convictions {
    background:url(../images/commitments-bg-2.jpg) no-repeat;
    background-size:cover;
    @media #{$mobile} {
      min-height: 480px;
    }
    .CommitmentsArticle-content-centered {
      background: #ffffff;
      padding: 3rem;
      color: #000000;
      font-size: 1rem;
      display: table;
      margin: 20%;
      position: absolute;
      @media #{$mobile} {
        margin: 10% 5%;
        padding:1rem 2rem;
      }
      h3 {
        text-align:center;
      }
    }
  }
  #discover {
    .Story-download-link {
      padding: 10px 20px;
      background: #3296d1;
      color: #ffffff;
      border-radius: 2px;
      text-align: center;
      text-decoration: none;
      display: table;
      margin: 25px auto;
    }
  }
  #pilliars {
    @media #{$mobile} {
      /*min-height:100vh;*/
      height:auto;
      max-height:1600px;
      > ul {
        position:relative !important;
        background: url(../images/pilliars-bg.jpg) #333333;
        background-size:cover;
      }
    }
    .Header-scrollLinkContainer {
      width:85%;
      text-align:right;
    }
  }
  &-title {
    margin: 0;
  }
  &-subtitle {
    margin: 0;
  }
  &-content {
    box-sizing: border-box;
    height: auto; width: 100%;
    z-index: 100;
    padding-bottom: 3em;
    @media #{$tablet} {
      height: 100%;
      padding-bottom: 0;
    }
  }
}

.CommitmentsArticle {
  height: auto;
  @media #{$tablet} {
    height: 100%;
  }
  &-figure {
    box-sizing: border-box;
    position: inherit;
    top: auto; left: auto;
    height: auto; width: 100%;
    margin: 0; padding: 3em 3em 0 3em;
    background: none;
    @media #{$tablet} {
      position: absolute;
      top: 0; left: 0;
      height: 100%; width: 50%;
      padding: 0;
      background: url(../images/commitments-article-img.jpg) 70% 0 no-repeat;
      background-size: cover;
    }
  }
  &-img {
    display: block;
    width: 100%;
    @media #{$tablet} {
      display: none;
      width: auto;
    }
  }
  &-content {
    box-sizing: border-box;
    position: relative;
    top: auto; left: auto;
    height: auto;
    padding: 3em 3em 0 3em;
    @media #{$tablet} {
      height: 100%;
      position: absolute;
      top: 0; left: 50%;
    }
  }
  &-textContainer {
    overflow: hidden;
    height: auto;
    @media #{$tablet} {
      height: 80%;
    }
  }
}



.Commitments-listJob {
  @media #{$mobile} {
    width:100%;
  }

}
.CommitmentsJob {
  position: relative;
  box-sizing: border-box;
  height: 89px;
  height: 20vh;
  line-height:89px;
  text-align:center;
  width: 100%;
  @media #{$tablet} {
    width: 400px;
  }
  @media #{$mobile} {
    box-sizing:border-box;
    padding:0 20px;
    text-align:left;
    height: 89px;
  }
  background:transparentize(#000000, .60);
  border-bottom: transparentize(#fff, .85) 1px solid;
  cursor: pointer;
  transition: background-color 0.5s ease;
  &.isSelected,
  &:hover {
    z-index: 9999;
    background:transparentize(#7a7a7a, .50);
    border-bottom: transparentize(#fff, .55) 1px solid;
    &:after {
      display: inline-block;
      position: absolute;
      top: 100%; left: 50%;
      margin-top: -11px;
      margin-left: -11px;
      content: url(../images/commitments-triangle.png);
      transform: rotate(90deg);
      @media #{$tablet} {
        top:5vh;
        height:20vh;
        left: auto;
        right: -11px;
        margin-top: 0;
        margin-left: 0;
        transform: rotate(0deg);
      }
      @media #{$mobile} {
        top:60px;
      }
    }
  }
  &-icon {
    display:inline-block;
    height:59px;
    margin-right:20px;
    vertical-align:middle;
    width:59px;
    @include border-radius(30px);
    @media #{$tablet} {
      margin-top: 5vh;
    }
  }
  &-text {
    color:#fff;
    display: inline-block;
    line-height:1.5em;
    margin:0;
    font-size: .75em;
    text-align: left;
    vertical-align:middle;
    width:180px;
    @media #{$mobile} {
      width:auto;
      width: 66%;
      word-break: normal;
    }
    @media #{$tablet} {
      margin-top: 5vh;
    }
    strong {
      font-family: 'ralewaybold';
      font-weight:none;
      width:100%;
    }
  }
}

.Commitments {
  &-listJob {
    margin: 0; padding: 0;
    list-style: none;
    position: absolute;
    top: 0; left: 0;
    z-index: 101;
  }
  &-jobContainer {
    position: relative;
    min-height: 100%;
    background:url(../images/pilliars-bg.jpg);
    background-size:cover;
  }
  &-content {
    @media #{$mobile} {
      padding-bottom:0;
    }
  }
  &-job {
    /*position: absolute;*/
    top: 0; left: 0;
  }
  .Job {
    margin-left: 400px;
    width:calc(100% - 400px);
    height:0;
    &-header {
      box-sizing: border-box;
      height: 267px;
    }
    &-shadow {
      height: 100%; width: 100%;
      background: rgba(0,0,0,.2);
    }
    &-content {
      box-sizing: border-box;
      display: table;
      width: 100%;
      margin: 0; padding: 2em 1.5em 0 20px;
      @media #{$tablet} {
        padding:2em 70px 0 60px;
      }
      @media #{$mobile} {
        padding-top:3em;
      }
    }
    &-h3 {
      color: #ffffff;
      font-family: ralewayextrabold;
      font-size: .875em;
      text-transform: uppercase;
      margin:0 0 25px 0;
      text-align:center;
    }
    &-icon {
      width: 12%;
      display: table;
      margin: auto;
    }
    &-textContainer {
      display: block;
      position:absolute;
      @media #{$mobile} {
        position:relative;
      }
      ul {
        color:#ffffff;
        margin-bottom: 20px;
        li {
          color:#ffffff;
          margin-bottom: 10px;
        }
      }
    }
    &-paragraph {
      font-family: ralewaymedium;
      font-size: .875em;
      line-height: 1.66;
      margin-bottom:25px;
      color:#ffffff;
      text-align:left;
      padding:0 12%;
      margin-top: 5%;
      @media #{$mobile} {
        padding:0;
      }
    }
    ul {
      margin:0;
      margin: 0;
      padding: 0 12% 12%;
      color: #ffffff;
      font-size: 0.875em;
      li {
        margin-bottom:10px;
      }
      a {
        color:#ffffff;
        line-height: 1em;
        font-size:0.875em;
      }
    }
  }
}