@import '../../../styles/variables.scss';

.ValueChain {
  height: 100%; width: 100%;
  background: url(../images/valuechain-bg.jpg) no-repeat;
  background-size: cover;
  &-title {
    margin: 0;
  }
  &-subtitle {
    margin: 0;
  }
}

