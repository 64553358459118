// media
$mobile: "only screen and (max-width:895px)";
$tablet: "only screen and (min-width:769px)";
$desktop: "only screen and (min-width: 768px) and (max-width: 5000px)";
$onlyTablet: "only screen and (min-width: 768px) and (max-width: 1200px)";
$onlySmallTablet: "only screen and (min-width: 768px) and (max-width: 1000px)";
$onlyBigDesktop: "only screen and (min-width: 1350px) and (max-width: 1600px)";
$onlyGiantDesktop: "only screen and (min-width: 1600px) and (max-width: 1920px)";
$minTablet: "768px";
$maxTablet: "1200px";
$smallTablet: "1000px";

// media for Rexel And You -- transform us
$smallMobileRaYTransformUs: "only screen and (max-width:375px)";
$smallRaYTransformUs: "only screen and (min-width: 769px) and (max-width: 875px)";
$mediumRaYTransformUs: "only screen and (min-width: 876px) and (max-width: 1500px)";

// DESKTOP
$only1920: "only screen and (min-width:1900px)";
$only1680: "only screen and (width:1680px)";
$only1366: "only screen and (width:1366px)";
$only1360: "only screen and (width:1360px)";

$only1366Desktop: "only screen and (min-width: 768px) and (max-width: 1366px)";



// Color used on the home page
$color-white: #fff;
$color-azure: #2996cf;
$color-pink: #d56a68;
$color-salmon: #ed7f5a;
$color-purple: #442e42;
$color-blue: #015289;
$color-sand: #dcd48d;
$color-lightblue: #6dcdda;
$color-lightgrey: #ededed;
$color-green: #005c30;

// Rexel And You colors
$color-yellow:#eed441;
$ray-color-orange:#ff7a34;
$ray-color-purple:#b569a9;
$ray-color-peach:#ff695b;
$ray-color-blue:#1ba3d4;
$ray-color-green:#84c551;

// MIXINS
@mixin border-radius($radius) { /* border-radius */
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}
@mixin transition($transition) {
	-moz-transition:    $transition;
	-o-transition:      $transition;
	-webkit-transition: $transition;
	transition:         $transition;
}
