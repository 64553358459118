@import '../../../styles/variables.scss';


.SuccessStories {
  height: 100%; width: 100%;
  background: url(../images/successstories-bg.jpg) no-repeat;
  background-size: cover;
  &-title {
    margin: 0;
  }
  &-subtitle {
    margin: 0;
  }
  &-header {
    @media #{$mobile} {
      background: url(../images/successstories-bg.jpg) no-repeat;
      background-size: cover;
    }
  }
  &-content {
    height: 100%; width: 100%;
    z-index: 100;
  }
  &-listPeople {
    margin: 0; padding: 0;
    list-style: none;
    position: absolute;
    top: 0; left: 0;
    z-index: 101;
  }
  &-storyContainer {
    position: relative;
  }
  &-story {
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    &:nth-child(1) { opacity: 1; }
  }
}
.SuccessStoriesPeople {
  position: relative;
  box-sizing: border-box;
  min-height: 150px; width: 100%;
  transition:0.3s;
  @media #{$tablet} {
    width: 400px;
  }
  border-bottom: transparentize(#fff, .8) 1px solid;
  background: #3297d1;
  cursor: pointer;
  &.isSelected,
  &:hover {
    z-index: 9999;
    background: #004c94;
    border-bottom: transparent 1px solid;
    &:after {
      display: inline-block;
      position: absolute;
      top: 100%; left: 50%;
      margin-top: -11px;
      margin-left: -11px;
      content: url(../images/successstoriespeople-triangle.png);
      transform: rotate(90deg);
      @media #{$tablet} {
        top: 50%; left: auto; right: -11px;
        margin-top: -11px;
        margin-left: 0;
        transform: rotate(0deg);
      }
    }
    &.isSelected {
      box-shadow: 0 10px 20px rgba(0,0,0,.2);
    }
  }
  &-face {
    position: absolute;
    bottom: 0; left: 0;
  }
  &-text {
    padding: 1.5em 5% 2.5em 120px; margin: 0;
    font-size: .75em;
    color: #fff;
    @media #{$tablet} {
      padding: 1.5em 5% 1.5em 35%;
    }
  }
  &-author {
    text-align: right;
    margin: 0; padding: 0 5% .3em 120px;
    font-size: .75em;
    font-weight: bold;
    color: #fff;
  }
  &-job {
    text-align: right;
    margin: 0; padding: 0 5% 2.5em 120px;
    font-size: .6em;
    color: #fff;
  }
}
.Story {
  border-top: #3297d1 5px solid;
  margin-left: 400px;
  &-picture {
    width:100%;
  }
  &-header {
    box-sizing: border-box;
    height: 280px;
    border-bottom: #442e42 5px solid;
  }
  &-shadow {
    height: 100%; width: 100%;
    background: rgba(0,0,0,.2);
  }
  &-content {
    box-sizing: border-box;
    display: table;
    margin: 0; padding: 2em 1.5em 0 20px;
    @media #{$tablet} {
      padding: 3em 0 0 20px;
    }
  }
  &-h3 {
    font-family: ralewayextrabold;
    font-size: .875em;
    color: #004c94;
    text-transform: uppercase;
  }
  &-textContainer {
    display: block;
    @media #{$tablet} {
      display: table-cell;
      vertical-align: top;
      width: 60%;
    }
  }
  &-middleSpace {
    display: none;
    @media #{$tablet} {
      display: table-cell;
      width: 5%;
    }
  }
  &-verbatimContainer {
    box-sizing: border-box;
    display: block;
    @media #{$tablet} {
      display: table-cell;
      vertical-align: middle;
      width: 20%;
    }
  }
  &-rightSpace {
    display: none;
    @media #{$tablet} {
      display: table-cell;
      width: 5%;
    }
  }
  &-paragraph {
    font-family: ralewaymedium;
    font-size: .875em;
    line-height: 1.4;
  }
  &-intertitre {
    display: block;
    font-family: ralewaybold;
    font-size: .875rem;
    color: #000;
  }
  &-verbatimNumber {
    font-family: ralewaythin;
    font-size: 3em;
    line-height: 1.1;
    &.small {
      font-size: 2em;
    }
  }
  &-exergue {
    font-family: ralewaylight;
    font-size: 1.8em;
    font-style: italic;
    line-height: 1.1;
    @media #{$tablet} {
      font-size: 2.5em;
    }
  }
}
.StoryHeader {
  position: relative;
  &.background-1 { background: url(../images/storyheader-img-3.jpg) 0 70% no-repeat; background-size: cover; }
  &.background-2 { background: url(../images/storyheader-img-2.jpg) 0 70% no-repeat; background-size: cover; }
  &.background-3 { background: url(../images/storyheader-img-1.jpg) 0 70% no-repeat; background-size: cover; }
  &.background-4 { background: url(../images/storyheader-img-4.jpg) 0 70% no-repeat; background-size: cover; }
  &-title {
    position: absolute;
    top: 50%; left: 20px;
    margin: -1em 0 0 0;
    font-family: ralewaymedium;
    color: #fff;
    line-height: 1.4;
    text-shadow: 3px 3px 6px #000000;
    width:80%;
    @media #{$tablet} {
      left: 70px;
    }
    @media #{$mobile} {
      top: 25%;
      width:90%;
    }
    &:after {
      display: block;
      margin-top: -.4em;
      content: "___";
    }
  }
}