@import 'jquery-jvectormap-2.0.0',
		'jquery.mCustomScrollbar',
		'font',
		'variables',
		'header',
		'nav';

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

html, body {
	font-family: ralewayregular;
	height: 100%;
	margin: 0;
    //background:#2996cf;
	@media #{$onlyTablet} {font-size: 16px;}
}

strong, b  {
  font-family:ralewaybold;
}

.PageWrapper {
	visibility: hidden;
	font-family: ralewayregular;
	height: 100%;
	&.visible {
		visibility: visible;
	}
}

.PageContent {
	z-index: 7000;
	height: 100%;
}

.Content {
	position: relative;
}

.responsive_img{
	width: 75% !important;
	@media screen and(max-width: 1366px){
		width: 65%;
	}
	@media screen and(max-width: 1024px){
		width: 100%;
	}
}

.Title {
	font-family: ralewaybold;
	font-size: 1.125em; // 20px/16px
	font-weight: normal;
	color: #fff;
	text-transform: uppercase;
}
.Subtitle {
	font-family: ralewaylight;
	font-size: .875em; // 14px
	font-weight: normal;
	color: #fff;
	text-transform: uppercase;
}

.VideoPlayer {
	z-index: 9999;
	box-sizing: border-box;
	display: table;
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	margin: 0 auto; padding: 0;
	background: rgba(10,10,10,.8);
	&-container {
		box-sizing: border-box;
		display: table-cell;
		vertical-align: middle;
		padding: 0;
	}
	&-player {
		display: table;
        margin:auto;
		position: relative;
		width: 75%;
		border: rgb(10,10,10) 6px solid;
		box-shadow: 0 0 30px rgb(0,0,0);
        border:none;
        @media #{$mobile} {
          width: 85%;
        }
		&.story {
			border: rgb(100,100,100) 4px solid;
			box-shadow: none;
			margin-bottom: 20px;
		}
	}
	&-closePlayer {
		position: relative;
		top: 0;
        margin-top: -38px;
        right: 12.33%;
        font-size:1.5em;
		float: right;
		color: transparentize(#fff, 0.5);
		text-decoration: none;
        font-family: ralewaybold;
		&:hover {
			color: transparentize(#fff, 0.1);
		}
        z-index: 150;
        @media #{$mobile} {
          top: 0;
          right: 7.33%;
        }
	}
}

.HelpToVerticalCenter {
	display: table-cell;
	width: 100%;
	margin: 0; padding: 0;
	vertical-align: middle;
}

.visibleOnlyTablet {
	display: none;
	@media #{$tablet} {
		display: block;
	}
}
.visibleOnlyMobile {
	display: block;
	@media #{$tablet} {
		display: none;
	}
}

.PageBack {
	z-index: 9000;
	position: fixed;
	bottom: 30px; right: 10px;
	height: auto; width: 50px;
	&-top {
		cursor: pointer;
		display: block;
		margin-bottom: 10px;
	}
	&-home {
		cursor: pointer;
		display: block;
	}
	&-img {
		width: 100%;
	}
}


/*
*
* Animations NG-VIEW
*
*/
.well[ng-view]{
  min-height:100vh;
  height: 100%;
  width:100%;
}

.ng-view-container {
  position: relative;
}

[ng-view].ng-enter, [ui-view].ng-leave {
  position: absolute;
  left: 0;
  right: 0;
  transition:all 1.3s ease-in-out;
}

[ng-view].ng-enter {
  opacity: 0.16;
}

[ng-view].ng-enter-active {
  opacity: 1;
}

[ng-view].ng-leave {
  opacity: 1;
}

[ng-view].ng-leave-active {
  opacity: 0.16;
}
