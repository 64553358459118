.PageNav {
  .lang-selector-wrapper {
    @media screen and (min-width:1024px){
      display:none;
    }
    width: 50px;
    overflow: hidden;
    float: right;
    margin-right: 10px;
    background: #2996cf;
    margin-top: 10px;
    height: 20px;
    padding: 5px 5px 5px 0;
    box-shadow: 0px 5px 3px rgba(0,0,0,0.16);
    .lang-selector {
      padding-left: 5px;
      color: #ffffff;
      width: 80px;
      background: transparent;
      border: 0;
      background: url("../images/ico-select.png") no-repeat 35px 5px;
      option {
        background: #2996cf;
      }
    }
  }
  box-sizing: border-box;
  position: fixed;
  top: 0; left: 0;
  height: 100%;
  background: #004c94;
  z-index: 8000;
  &-ul {
    box-sizing: border-box;
    height: 100%;
    margin: 0; padding: 52px 0 0;
    list-style: none;
  }
  &-linkContainer {
    position: relative;
    box-sizing: border-box;
    display: table;
    width: 100%;
    height: 12%;
    @media screen and (max-width:480px){
      height: 11.5% !important;
    }
    padding: 0 2em 0 1em;
    overflow: hidden;
    border-bottom: #2667a4 1px solid;
    &:nth-child(9){
      border: none;
    }
    &.fade {
      .Title,
      .Subtitle {
        opacity: .3;
      }
    }
  }
  &-textContainer {
    display: table-cell;
    vertical-align: middle;
    text-decoration: none;
    padding: 0 1em;
  }
  &-subtitle {
    display: block;
    @media screen and (max-width:480px){
      font-size:0.575em !important;
    }
  }
  &-title {
    display: block;
    @media screen and (max-width:480px){
      font-size:0.75em !important;
    }
  }
  &-link {
    position: absolute;
    top: 0; left: 0;
    display: block;
    height: 100%; width: 100%;
    z-index: 104;
  }
}
